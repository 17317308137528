import React, { memo } from 'react'
import { Route } from 'react-router-dom'
import { useUser } from '../..'
import PropTypes from 'prop-types'

const SecureRoute = ({ path, authenticated, permissions, data, ...props }) => {
  const { isAuthenticated, hasPermission } = useUser()

  if (authenticated && !isAuthenticated) return ''

  if (permissions && !hasPermission(permissions)) return ''

  if (data) {
    const { component: Component, ...rest } = props
    return (
      <Route path={path} {...rest} render={() => <Component {...data} />} />
    )
  }
  return <Route path={path} {...props} />
}

export default memo(SecureRoute)

SecureRoute.propTypes = {
  authenticated: PropTypes.bool,
  data: PropTypes.any,
  path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  permissions: PropTypes.any
}

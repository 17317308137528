import ValidatorProvider from "./ValidatorProvider";

export const isName = value => {
  // eslint-disable-next-line
  if (!/^([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s]?)+([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])+[\s]?([A-Za-zÁÉÍÓÚñáéíóúÑ]{0}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])?$/i.test(value)) {
    return false;
  }
  return true;
};

export const NameRuler = {

  validator(rule, value) {
    if (isName(value) || !value) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidName';

    return Promise.reject(ValidatorProvider.t(message));
  },

};

export const PHONE_REGEX = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/;

export const isPhoneNumber = (value = '', cuba) => {
  let phone = cuba && value.length < 10 ? `53${value}` : value;
  return phone.match(PHONE_REGEX);
};


export const PhoneNumberRuler = {

  validator(rule, value) {

    if (isPhoneNumber(value) || !value || !value.trim()) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidPhoneNumber';

    return Promise.reject(ValidatorProvider.t(message));
  },

};

export const PhoneCubaNumberRuler = {

  validator(rule, value) {

    if (isPhoneNumber(value, true) || !value || !value.trim()) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidPhoneNumber';

    return Promise.reject(ValidatorProvider.t(message));
  },

};


export const LATTER_AND_NUMBER_REGEX = /^([a-z]|[A-Z]|\s|\d)*$/;

export const isLatterAndNumber = (value = '') => {
  return !!value.match(LATTER_AND_NUMBER_REGEX);
};


export const LatterAndNumberRuler = {

  validator(rule, value) {

    if (isLatterAndNumber(value) || !value || !value.trim()) {
      return Promise.resolve();
    }

    const message = rule.message || 'invalidLetterAndNumber';

    return Promise.reject(ValidatorProvider.t(message));
  },

};


import { ApiClientService } from './index'

class EntityApiService {
  constructor(path) {
    this.path = path
  }

  /**
   *Get the request path
   * @param {string| null} concat custom path to the entity path
   * @param {any} params od the request, util to create custom
   * path url according to the request body
   * @return {string}
   **/
  getPath(concat, params) {
    return this.path + (concat || '')
  }

  search = (params, config) => {
    return ApiClientService.post(
      `${this.getPath('/search', params)}`,
      params,
      config
    )
  }

  getOne = (id, config) => {
    return ApiClientService.get(`${this.getPath(null, null)}/${id}`, config)
  }

  save = (params, config) => {
    return ApiClientService.post(this.getPath(null, params), params, config)
  }

  update = (id, params, config) => {
    if (typeof id === 'object') {
      params = id
      id = id._id
    }
    return ApiClientService.patch(
      this.getPath(null, params) + '/' + id,
      params,
      config
    )
  }

  delete = (id, params, config) => {
    return ApiClientService.delete(
      `${this.getPath(null, params)}/${id}`,
      params,
      config
    )
  }

  deleteMany = (data, config = {}) => {
    return ApiClientService.delete(`${this.getPath(null, data)}`, {
      ...config,
      data
    })
  }

  recover = (id, params, config) => {
    return ApiClientService.patch(
      `${this.getPath(null, params)}/${id}/recover`,
      params,
      config
    )
  }
}

export default EntityApiService

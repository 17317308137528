const OperatorEnum = {
  AND: 'AND',
  OR: 'AND',
};


const getFiltersByOperator = (filter1, filter2, operator) => {
  if (filter1.type === operator) {
    return {filter1, filter2}
  }
  if (filter2.type === operator) {
    return {filter1: filter2, filter2: filter1}
  }
};

class FilterFactory {
  setup(types) {
    this.types = types;
  }

  factory(filter) {
    if (!filter || !Object.keys(filter).length)
      return new this.types.EMPTY();
    if (!filter.type)
      return new this.types.TERM(filter);

    const type = filter.type.toUpperCase();

    if (filter.filters) {
      if (!filter.filters.length)
        return new this.types.EMPTY();
      if (filter.filters.length === 1)
        return this.factory(filter.filters[0]);
    }

    if (this.types[type])
      return new this.types[type](filter);
    throw new Error(`Invalid type '${type}'`);
  }

  get OPERATOR() {
    return OperatorEnum;
  }


  operator(filter1, filter2, operator = OperatorEnum.AND) {
    const filter = {
      type: operator,
      filters: [filter1, filter2]
    };
    return this.factory(filter);
  }

  add(filter1, filter2, operator = OperatorEnum.AND) {

    if (!filter1 || !filter1.isFilter)
      filter1 = this.factory(filter1);

    if (!filter2 || !filter2.isFilter)
      filter2 = this.factory(filter2);

    if (!filter1 || filter1.isEmpty) {
      return filter2
    }

    if (!filter2 || filter2.isEmpty) {
      return filter1
    }
    
    const filters = getFiltersByOperator(filter1, filter2, operator);

    // operator[1] & * => operator[1,*]
    // * & operator[1] => operator[1,*]
    if (filters) {
      return filters.filter1.clone().add(filters.filter2);
    }
    // * & * => and [*,*]
    return this.operator(filter1, filter2, operator);
  }


  removeByField(filter, filed) {
    filter.removeField(filed);
    return filter;
  }

  removeByFilter(filter, filterToRemove) {
    filter.removeFilter(filterToRemove);
    return filter;
  }


}

export default new FilterFactory();

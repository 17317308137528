import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import ApiClientService from '../services/ApiClientService'

const AuthContext = React.createContext()
const FullPageSpinner = () => 'loading'

function AuthProvider({
  user,
  reload,
  error,
  isRejected,
  isLoading,
  isSettled,
  authClient,
  PageLoader,
  ...props
}) {
  const [firstAttemptFinished, setFirstAttemptFinished] = useState(false)

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true)
    }
  }, [isSettled])

  React.useEffect(() => {
    ApiClientService.onError(reload, 401)
  }, [reload])

  const { login, register, logout } = useMemo(() => {
    const login = (form) => authClient.login(form).then(reload)
    const register = (form) => authClient.register(form).then(reload)
    const logout = () => authClient.logout().then(reload)

    return {
      login,
      register,
      logout
    }
  }, [reload])

  if (!firstAttemptFinished) {
    if (isLoading) {
      return PageLoader ? <PageLoader /> : <FullPageSpinner />
    }
    if (isRejected) {
      return (
        <div style={{ color: 'red' }}>
          <p>Uh oh... There's a problem. Try refreshing the app.</p>
          <pre>{error.message}</pre>
        </div>
      )
    }
  }

  return (
    <AuthContext.Provider
      value={{ user, login, logout, register, reload }}
      {...props}
    />
  )
}

function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  const { user, login, logout, register, reload } = context
  return { user, login, logout, register, reload } // this is to help ide to auto complete sentences
}

export { AuthProvider, useAuth }

AuthProvider.propTypes = {
  PageLoader: PropTypes.any,
  authClient: PropTypes.any,
  error: PropTypes.any,
  isLoading: PropTypes.bool,
  isRejected: PropTypes.bool,
  isSettled: PropTypes.bool,
  reload: PropTypes.func,
  user: PropTypes.object
}

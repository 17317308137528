import IFilter from './IFilter';

/**
 * This class handle the Term Filters
 * @author Yadian
 * Example
 *  {
        "type": "TERM",
        "field": "um.id.keyword",
        "value": "bb4cd88-c6b8-4a77-956a-0671189be008"
    }
 **/


export default class TermFilter extends IFilter {

    /**
     * @param filter {Object}
     **/
    constructor(filter) {
        super();
        const {field, value} = filter;
        this.type = 'TERM';
        this.field = field;
        this.value = value;
        this.filterObject = filter
    }

    clone() {
        return new TermFilter(this.toQuery());
    }

    toQuery() {
        if(!this.field)
            return this.filterObject;

        if (this.value === undefined)
            return;
        return {
            type: 'TERM',
            field: this.field,
            value: this.value
        };
    }

    toMongoQuery() {
        if(!this.field)
            return this.filterObject;
        return {[this.field]: this.value};
    }
}

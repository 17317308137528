import ValidatorProvider from "./ValidatorProvider";

export const scorePassword = (pass) => {
  let score = 0;
  if (!pass || !pass.trim())
    return score;

  // award every unique letter until 5 repetitions
  const letters = new Object();
  for (let i = 0; i < pass.length; i++) {
    letters[pass[i]] = (letters[pass[i]] || 0) + 1;
    score += 5.0 / letters[pass[i]];
  }

  // bonus points for mixing it up
  let variations = {
    digits: /\d/.test(pass),
    lower: /[a-z]/.test(pass),
    upper: /[A-Z]/.test(pass),
    nonWords: /\W/.test(pass),
  };

  let variationCount = 0;
  for (let check in variations) {
    variationCount += (variations[check] == true) ? 1 : 0;
  }
  score += (variationCount - 1) * 10;

  return parseInt(score);
};

export const isPassword = value => {
  return scorePassword(value) > 24;
};


export const PasswordRuler = {

  validator(rule, value) {
    if (!value || isPassword(value)) {
      return Promise.resolve();
    }

    const message = rule.message || 'passStrength';

    return Promise.reject(ValidatorProvider.t(message));
  },

};

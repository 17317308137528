import React, { useCallback, useMemo } from 'react'
import forEach from 'lodash/forEach'
import { useAuth } from './AuthContext'
import { ApiClientService } from '../services'

const UserContext = React.createContext()
const REACT_APP_SUPERADMIN_ROLE =
  process.env.REACT_APP_SUPERADMIN_ROLE || 'ADMIN'

function _hasPermission(userPermissions, permission) {
  if (!permission) return true
  if (!userPermissions) return false
  return userPermissions.some(
    (userPermission) =>
      userPermission === REACT_APP_SUPERADMIN_ROLE ||
      userPermission === permission.toUpperCase()
  )
}

function _hasRole(role, user) {
  return user.roles.some((userRole) => userRole === role)
}

function UserProvider(props) {
  const { user } = useAuth()

  const fullUserPermissions = useMemo(() => {
    if (!user) return []

    let userPermissions = user.permissions
    if (user.permissions && !Array.isArray(user.permissions)) {
      userPermissions = []
      forEach(user.permissions, (per) => {
        userPermissions = userPermissions.concat(per)
      })
    }
    return userPermissions
  }, [user])

  const hasPermission = useCallback(
    (permissions) => {
      if (!user) return false

      let userPermissions = fullUserPermissions
      if (
        user.permissions &&
        !Array.isArray(user.permissions) &&
        ApiClientService.getSpace()
      ) {
        userPermissions = user.permissions[ApiClientService.getSpace()]
      }

      if (!Array.isArray(permissions)) permissions = [permissions]
      return !permissions.some(
        (permission) => !_hasPermission(userPermissions, permission)
      )
    },
    [user, fullUserPermissions]
  )

  const hasRoles = useCallback(
    (roles, needAll) => {
      if (!roles) return true
      if (!user || !user.roles) return false
      if (Array.isArray(roles)) {
        if (needAll) return !roles.some((role) => !_hasRole(role))
        else return roles.some((role) => _hasRole(role))
      }
      return _hasRole(roles)
    },
    [user]
  )

  return (
    <UserContext.Provider
      value={{ user, hasPermission, hasRoles }}
      {...props}
    />
  )
}

function useUser() {
  const context = React.useContext(UserContext)
  if (context === undefined) {
    return {
      isAuthenticated: false,
      user: null,
      hasPermission: () => false,
      hasRoles: () => false
    }
  }
  // this is just for code completation
  return {
    isAuthenticated: !!context.user,
    user: context.user,
    hasPermission: context.hasPermission,
    hasRoles: context.hasRoles
  }
}

export { UserProvider, useUser }

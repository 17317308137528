import React, { memo } from 'react'
import map from 'lodash/map'
import { Redirect, Route, Switch } from 'react-router-dom'
import SecureRoute from './SecureRoute'
import PropTypes from 'prop-types'
import { getPaths } from './utils'

function RouteLoader({ routes, prefix, notfoundRedirect }) {
  return (
    <Switch>
      {map(routes, ({ path, ...route }, key) => (
        <SecureRoute key={key} {...route} path={getPaths(prefix, path)} />
      ))}
      {notfoundRedirect && (
        <Route
          path='*'
          name='page_no_found'
          component={() => <Redirect to={notfoundRedirect} />}
        />
      )}
    </Switch>
  )
}

export default memo(RouteLoader)

RouteLoader.propTypes = {
  notfoundRedirect: PropTypes.string,
  prefix: PropTypes.string,
  routes: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
}
